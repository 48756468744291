export default {
  country: require('./country.png'),
  demographic: require('./demographic.png'),
  education: require('./education.png'),
  employment: require('./employment.png'),
  financial: require('./financial.png'),
  location: require('./location.png'),
  gender: require('./gender.png'),
  religion: require('./religion.png'),
  age: require('./age.png'),
  discrimination: require('./discrimination.png'),
  governance: require('./governance.png'),
  shockFragility: require('./shock_fragility.png'),
  socioEconomic: require('./socio_economic.png'),
  spacialExclusion: require('./spatial_exclusion.png'),
  children: require('./children.png'),
}