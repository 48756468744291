// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React, { useState, useRef, useEffect, Fragment } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import MaterialPaper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import ListItemText from '@material-ui/core/ListItemText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'

import ContentLoader from "react-content-loader";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { CSVLink } from "react-csv";

import IMAGES from './images'

const mainColor = '#234c6a'
const secondaryColor = '#0265b5'
const fontFamily = "'nova', sans-serif"

HighchartsExporting(Highcharts)

const CHARTS_PALETTE = [
  '#003f5c', '#665191', '#d45087', '#ff7c43', '#ffcb0d', '#eded8b', '#a9d48f', '#7ec790',
  '#d4e48d', '#fff587', '#ffa600', '#f95d6a', '#a05195', '#2f4b7c', '#002333',
]
const CHARTS_ORDERED_PALETTE = [
  '#002333', '#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43',
  '#ffa600', '#ffcb0d', '#fff587', '#eded8b', '#d4e48d', '#a9d48f', '#7ec790',
]

const ORDERED_QUESTIONS = [
  'q_027', 'q_032', 'q_039', 'q_040', 'q_041', 'q_049', 'q_054', 'q_060', 'q_061', 'q_068', 'q_076', 'q_082', 'q_087',
  'q_088', 'q_089', 'q_092', 'q_093', 'q_094', 'q_095', 'q_098', 'q_099', 'q_100', 'q_106', 'q_107', 'q_113', 'q_118',
  'q_120', 'q_127', 'q_132', 'q_133', 'q_134', 'q_142', 'q_143', 'q_146', 'q_152', 'q_160', 'q_167', 'q_185', 'q_186',
  'q_187', 'q_188', 'q_189', 'q_191', 'q_192', 'q_193', 'q_199',
]

const AVAILABLE_VIEWS = {
  chart: 'chart',
  table: 'table'
}

const useStyles = (locale) => {
  const rtl = locale === 'ar'

  return makeStyles(theme => ({
    root: {
      flexGrow: 1,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    icon: {
      paddingRight: 10,
    },
    iconImage: {
      paddingRight: 10,
      width: 29,
    },
    exportButton: {
      display: 'flex',
      float: rtl ? 'left' : 'right',
      marginBottom: 10,
    },
    exportText: {
      textDecoration: 'none',
      color: mainColor,
    },
    exportIcon: {
      color: mainColor,
      paddingRight: 10,
    },
    clickableIcon: {
      cursor: 'pointer',
      fontSize: 14,
      paddingRight: 5,
      paddingBottom: 3,
    },

    toggleButtonGroup: {
      paddingLeft: 10,
      display: 'initial',
    },

    paper: {
      padding: 0,
      paddingBottom: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      minHeight: 140,
    },
    paperContent: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    paperTitle: {
      fontFamily,
      backgroundColor: mainColor,
      color: 'white',
      paddingTop: 5,
      paddingBottom: 5,
      fontWeight: 800,
      fontSize: 18,

      textAlign: rtl ? 'right' : 'left',
      paddingLeft: rtl ? 0 : 20,
      paddingRight: rtl ? 20 : 0,
    },
    formControl: {
      width: '100%',
    },

    respondents: {
      fontSize: '4rem',
      padding: 0,
      margin: 0,
      color: mainColor,
    },

    switchButton: {
      paddingLeft: 20,
    },

    listItem: {
      whiteSpace: 'inherit',
    },

    radioGroup: {
      float: rtl ? 'left' : 'right',
      paddingRight: rtl ? 0 : 10,
      paddingLeft: rtl ? 50 : 0
    },

    title: {
      fontFamily,
    },

    appbar: {
      root: {
        backgroundColor: mainColor,
      },
      indicator: {
        backgroundColor: 'white',
      }
    },

    tableContainer: {
      maxHeight: 500,
    },

    viewAs: {
      paddingLeft: rtl ? 0 : 30,
      paddingRight: rtl ? 30 : 0,
      display: 'initial',
      fontSize: 14
    }
  }))
}

const SelectList = ({ id, title, value, changeValue, classes, values, loading, noneTitle = "None", locale, ...props }) => {
  const inputLabel = useRef(null)

  const [labelWidth, setLabelWidth] = useState(0)
  const disabled = props.disabled || values.length === 0

  if (!value && props.multiple) { value = [] }
  if (props.multiple) {
    props.renderValue = (selected) => selected.join(', ')
  }

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  if (loading) {
    return (
      <ContentLoader style={{ height: 56 }}>
        <rect x={locale === 'ar' ? 30 : 0} y="0" rx="4" ry="4" width="265" height="56" />
      </ContentLoader>
    )
  }

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id={`${id}-label`}>{title}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        ref={inputLabel}
        value={value}
        onChange={ev => changeValue(ev.target.value)}
        labelWidth={labelWidth}
        {...props}
        disabled={disabled}
      >
        <MenuItem value="">
          <em>{noneTitle}</em>
        </MenuItem>
        {values.map(item => (
          <MenuItem className={classes.listItem} key={`${id}-${item.value}`} value={item.value}>
            {props.multiple ? (
              <>
                <Checkbox checked={value.indexOf(item.value) > -1} />
                <ListItemText primary={item.title} />
              </>
            ) : (
                <>
                  {item.icon && (
                    item.image ? <img src={IMAGES[item.icon]} className={classes.iconImage} /> : <Icon className={classes.icon}>{item.icon}</Icon>
                  )}
                  {item.title}
                </>
              )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

SelectList.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  changeValue: PropTypes.func.isRequired,
  classes: PropTypes.object,
  values: PropTypes.array,
  loading: PropTypes.bool,
}

SelectList.defaultProps = {
  title: '',
  classes: {},
  values: [],
  loading: false,
}

const Paper = ({ title, children, rightIcon, locale, hideRightIcon }) => {
  const classes = useStyles(locale)()

  return (
    <MaterialPaper className={classes.paper}>
      <h3 className={classes.paperTitle}>
        {title}
        {hideRightIcon ? <></> : rightIcon}
      </h3>
      <div className={classes.paperContent}>
        {children}
      </div>
    </MaterialPaper>
  )
}

Paper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  rightIcon: PropTypes.object
}

const StackChart = ({ name, data, loading, byPercentage, title, subTitle, multianswerQuestion, demographicSplit, locale, translations, questionId }) => {
  const titleText = () => {
    if (subTitle) {
      return `
          <p><b>Question:</b> ${title}</p><br />
          <p><b>Split By:</b> ${subTitle}</p>
        `
    } else if (title) {
      return `<p><b>Question:</b> ${title}</p>`
    }

    return ''
  }

  if (data && data.numbers) {
    const classes = useStyles(locale)()
    const datum = byPercentage ? data.byPercentage : data.numbers
    const chart = useRef(null)
    const height = 25

    const stackedOptions = {
      chart: {
        type: 'bar',
        height: datum.series[0].data.length * (height * 2) + 250,
      },
      colors: ORDERED_QUESTIONS.includes(questionId) ? CHARTS_ORDERED_PALETTE : CHARTS_PALETTE,
      title: { text: titleText() },
      xAxis: { categories: datum.categories, },
      yAxis: {
        min: 0,
        max: byPercentage ? 100 : null, // (byPercentage && !demographicSplit) ? 100 : null,
        title: {
          text: byPercentage ? 'Percentage' : 'Values',
        },
      },
      tooltip: {
        pointFormat: '<span><span style="padding:0">{series.name}: </span>' +
          `<span style="padding:0"><b>{point.y${byPercentage ? ':.1f' : ''}} ${byPercentage ? '%' : ''}</b></span>`,
        useHTML: true
      },
      legend: {
        reversed: true,
      },
      plotOptions: {
        series: {
          stacking: (byPercentage && !multianswerQuestion) ? 'percent' : 'normal',
          events: {
            legendItemClick: (event) => {
              if (multianswerQuestion) {
                chart.current.chart.series.forEach((item) => {
                  item.setVisible(false, false)
                })
              }
            }
          },
          column: { pointWidth: height, },
          bar: { pointWidth: height, },
        },
        column: { pointWidth: height, },
        bar: { pointWidth: height, },
      },
      series: datum.series
    }

    useEffect(() => {
      if (multianswerQuestion) {
        chart.current.chart.series.forEach((item) => {
          item.setVisible(false, false)
        })

        chart.current.chart.series[0].setVisible(true, true)
      } else {
        chart.current.chart.series.forEach((item) => {
          item.setVisible(true, true)
        })
      }
    }, [data, byPercentage, multianswerQuestion])

    return (
      <>
        {/* <div style={{ width: '100%', display: 'inline-block' }}>
          <Button onClick={() => chart.current.chart.exportChart()} variant="contained" className={classes.exportButton}>
            <Icon className={classes.exportIcon}>get_app</Icon>
            <span className={classes.exportText}>{translations.export_chart}</span>
          </Button>
        </div> */}
        <HighchartsReact
          ref={chart}
          key={`${name}_${byPercentage}`}
          highcharts={Highcharts}
          options={stackedOptions}
        />
      </>
    )
  } else {
    return null
  }
}

const UNDPTable = ({ tableTitle, tableSubTitle, data, byPercentage, locale, translations, transposeTable, multianswerQuestion, forceFirstColumn = false }) => {
  const classes = useStyles(locale)()

  if (!data || !data.rows) {
    return (
      <h3 className={classes.title}>{translations.no_table}</h3>
    )
  }

  const { columns, rows, title } = data
  const dataToRender = byPercentage ? data.valuesByPercentage : data.values

  let csvData = [
    [translations.table, tableTitle]
  ]

  if (tableSubTitle) {
    csvData.push(
      [translations.split, tableSubTitle]
    )
  }

  let titleRow = [''];
  (transposeTable ? rows : columns).forEach((column, columnIndex) => {
    if (!(byPercentage && columnIndex === 0)) {
      titleRow.push(column)
    }
  })
  csvData.push(titleRow);

  (transposeTable ? columns : rows).forEach((row, rowIndex) => {
    let rowData = [row];

    (transposeTable ? rows : columns).forEach((column, columnIndex) => {
      if (!(byPercentage && columnIndex === 0)) {
        rowData.push(`${dataToRender[
          transposeTable ? columnIndex : rowIndex
        ][
          transposeTable ? rowIndex : columnIndex
        ]} ${byPercentage ? '%' : ''}`)
      }
    });

    csvData.push(rowData)
  })

  return (
    <>
      {multianswerQuestion ? (
        <h6>{translations.multipleanswer_disclaimer}</h6>
      ) : null}

      <CSVLink filename={`UNDP-AHDR-data-download.csv`}
        key={`download_${title}_${byPercentage}`}
        target="_blank" data={csvData}
      >
        <Button variant="contained" className={classes.exportButton}>
          <Icon className={classes.exportIcon}>get_app</Icon>
          <span className={classes.exportText}>{translations.export_table}</span>
        </Button>
      </CSVLink>

      <TableContainer className={classes.tableContainer} key={`table_${title}_${byPercentage}`}>
        <Table table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>{translations.question}</TableCell>
              <TableCell colSpan={(transposeTable ? rows : columns).length - 1}>{tableTitle}</TableCell>
            </TableRow>

            {tableSubTitle ? <TableRow>
              <TableCell>{translations.split}</TableCell>
              <TableCell colSpan={(transposeTable ? rows : columns).length - 1}>{tableSubTitle}</TableCell>
            </TableRow> : null}

            <TableRow>
              <TableCell key={`column_${title}_empty_${byPercentage}`}></TableCell>

              {(transposeTable ? rows : columns).map((column, columnIndex) => {
                if (forceFirstColumn || !(byPercentage && columnIndex === 0)) {
                  return (
                    <TableCell
                      key={`column_${title}_${column}_${byPercentage}`}
                      align='center'
                    >
                      {column}
                    </TableCell>
                  )
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {(transposeTable ? columns : rows).map((row, rowIndex) => (
              <TableRow hover tabIndex={-1} key={`row_${title}_${row}_${byPercentage}`}>
                <TableCell align='left'>
                  {row}
                </TableCell>

                {(transposeTable ? rows : columns).map((column, columnIndex) => {
                  if (forceFirstColumn || !(byPercentage && columnIndex === 0)) {
                    return (
                      <TableCell key={`value_${title}_${row}_${column}_${byPercentage}`} align={'center'}>
                        {`${dataToRender[
                          transposeTable ? columnIndex : rowIndex
                        ][
                          transposeTable ? rowIndex : columnIndex
                        ]} ${byPercentage ? '%' : ''}`}
                      </TableCell>
                    )
                  }
                }
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const WhiteRadio = withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const WhiteSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
    '&$checked + $track': {
      backgroundColor: 'white',
    },
  },
  checked: {},
  track: {},
})(Switch);

const BlueAppBar = withStyles({
  root: {
    backgroundColor: secondaryColor,
  },
  indicator: {
    backgroundColor: 'white',
  }
})(props => <AppBar {...props} />);

const UNDPFilter = ({ categories, splitBy, locale, translations }) => {
  const classes = useStyles(locale)()
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState('')
  const [question, setQuestion] = useState('')
  const [availableQuestions, setAvailableQuestions] = useState([])
  const [demographicSplit, setDemographicSplit] = useState('')
  const [optionalFiltersValues, setOptionalFiltersValues] = useState({})
  const [data, setData] = useState({
    respondents: 0,
    chartsResponseToQuestion: {},
    questionTable: {},
    chartsResponseBySplit: {},
    optionalFilters: {},
    filteredTable: {},
    freetextQuestion: false,
    multianswerQuestion: false,
  })
  const [chartsByPercentage, setChartsByPercentage] = useState({
    chartsResponseToQuestion: true,
    chartsResponseBySplit: true,
  })
  const [responseToQuestionView, setResponseToQuestionView] = useState(AVAILABLE_VIEWS.chart)
  const title = question ? availableQuestions.find(item => item.value === question).title : ''
  const subTitle = demographicSplit ? splitBy.find(item => item.value === demographicSplit).title : ''

  useEffect(() => {
    async function getAvailableQuestions() {
      setLoading(true)

      // API Call
      const response = await fetch(`/api/questions?category=${category}&locale=${locale}`)
      const apiData = await response.json()
      setAvailableQuestions(apiData)

      setLoading(false)
    }

    getAvailableQuestions()
  }, [category])

  useEffect(() => {
    loadData()
  }, [question, category, demographicSplit, optionalFiltersValues])

  useEffect(() => {
    if (data.freetextQuestion) {
      setResponseToQuestionView(AVAILABLE_VIEWS.table)
      setChartsByPercentage({
        chartsResponseBySplit: false,
        chartsResponseToQuestion: false
      })
    }
  }, [data])

  const loadData = async () => {
    setLoading(true)

    const response = await fetch('/api/filter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        category,
        question,
        demographic_split: demographicSplit,
        filter_values: optionalFiltersValues,
        locale: locale,
      })
    })
    const apiData = await response.json()
    setData(apiData)

    setLoading(false)
  }

  const otherFilters = () => {
    return (
      <Paper locale={locale}
        title={translations.optional_filters}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button onClick={() => setOptionalFiltersValues({})} size="small">
              <Icon className={classes.clickableIcon}>clear</Icon> {translations.clear_filters}
            </Button>

          </Grid>

          <Grid item xs={12}>
            <SelectList
              locale={locale}
              id="country"
              title={translations.country}
              loading={loading}
              noneTitle={translations.all}
              values={data.optionalFilters.countries}
              value={optionalFiltersValues.country}
              changeValue={value => setOptionalFiltersValues({ ...optionalFiltersValues, country: value })}
              classes={classes}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectList
              locale={locale}
              id="gender"
              title={translations.gender}
              loading={loading}
              noneTitle={translations.all}
              values={data.optionalFilters.genders}
              value={optionalFiltersValues.q_002}
              changeValue={value => setOptionalFiltersValues({ ...optionalFiltersValues, q_002: value })}
              classes={classes}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectList
              locale={locale}
              id="age"
              title={translations.age}
              loading={loading}
              noneTitle={translations.all}
              values={data.optionalFilters.ages}
              value={optionalFiltersValues.q_001}
              changeValue={value => setOptionalFiltersValues({ ...optionalFiltersValues, q_001: value })}
              classes={classes}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectList
              locale={locale}
              id="education"
              title={translations.education}
              loading={loading}
              noneTitle={translations.all}
              values={data.optionalFilters.educations}
              value={optionalFiltersValues.q_010}
              changeValue={value => setOptionalFiltersValues({ ...optionalFiltersValues, q_010: value })}
              classes={classes}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectList
              locale={locale}
              id="religion"
              title={translations.religion}
              loading={loading}
              noneTitle={translations.all}
              values={data.optionalFilters.religions}
              value={optionalFiltersValues.q_019}
              changeValue={value => setOptionalFiltersValues({ ...optionalFiltersValues, q_019: value })}
              classes={classes}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectList
              locale={locale}
              id="employmentStatus"
              title={translations.employment_status}
              loading={loading}
              noneTitle={translations.all}
              values={data.optionalFilters.employment_statuses}
              value={optionalFiltersValues.q_004}
              changeValue={value => setOptionalFiltersValues({ ...optionalFiltersValues, q_004: value })}
              classes={classes}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectList
              locale={locale}
              id="financialSituation"
              title={translations.financial_situation}
              loading={loading}
              noneTitle={translations.all}
              values={data.optionalFilters.financial_situations}
              value={optionalFiltersValues.q_009}
              changeValue={value => setOptionalFiltersValues({ ...optionalFiltersValues, q_009: value })}
              classes={classes}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectList
              locale={locale}
              id="geography"
              title={translations.geography}
              loading={loading}
              noneTitle={translations.all}
              values={data.optionalFilters.geography}
              value={optionalFiltersValues.q_015}
              changeValue={value => setOptionalFiltersValues({ ...optionalFiltersValues, q_015: value })}
              classes={classes}
            />
          </Grid>
        </Grid>

      </Paper>
    )
  }

  return (
    <Container className={classes.root}>
      <Grid container spacing={1}>
        <Grid item md={3} xs={12}>
          <Paper locale={locale} title={translations.select_a_category}>
            <SelectList
              locale={locale}
              id="category"
              title={translations.category}
              loading={loading}
              values={categories}
              value={category}
              changeValue={v => { setCategory(v), setQuestion('') }}
              classes={classes}
            />
          </Paper>
        </Grid>

        <Grid item md={3} xs={12}>
          <Paper locale={locale} title={translations.select_a_question}>
            <SelectList
              locale={locale}
              id="question"
              title={translations.question}
              loading={loading}
              disabled={availableQuestions.length === 0}
              values={availableQuestions}
              value={question}
              changeValue={setQuestion}
              classes={classes}
            />
          </Paper>
        </Grid>

        <Grid item md={3} xs={12}>
          <Paper locale={locale} title={translations.select_method_of_split}>
            <SelectList
              locale={locale}
              id="demographicSplit"
              title={translations.split}
              loading={loading}
              values={splitBy}
              value={demographicSplit}
              changeValue={setDemographicSplit}
              classes={classes}
            />
          </Paper>
        </Grid>

        <Grid item md={3} xs={12}>
          <Paper locale={locale} title={translations.respondants}>
            {loading ? (
              <ContentLoader style={{ height: 56 }}>
                <rect x={locale === 'ar' ? 30 : 0} y="0" rx="4" ry="4" width="270" height="56" />
              </ContentLoader>
            ) : (
                <h1 className={classes.respondents}>{data.respondents}</h1>
              )
            }
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper locale={locale}
            title={translations.total_responses_by_split_and_question}
            hideRightIcon={data.freetextQuestion}
            rightIcon={
              <>
                <div className={classes.viewAs}>
                  {translations.view_as}
                  <ToggleButtonGroup
                    class={classes.toggleButtonGroup}
                    exclusive
                    onChange={(event, value) => {
                      setChartsByPercentage({
                        ...chartsByPercentage,
                        chartsResponseToQuestion: value
                      })
                    }}
                    value={chartsByPercentage.chartsResponseToQuestion}
                  >
                    <ToggleButton class={classes.toggleButton} value={true} aria-label={translations.show_percentage}>
                      %
                  </ToggleButton>
                    <ToggleButton class={classes.toggleButton} value={false} aria-label={translations.show_count}>
                      1 2 3
                  </ToggleButton>
                  </ToggleButtonGroup>
                </div>

                <RadioGroup
                  aria-label="position"
                  name="position"
                  value={responseToQuestionView}
                  row
                  className={classes.radioGroup}
                  onChange={event => {
                    setResponseToQuestionView(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value={AVAILABLE_VIEWS.chart}
                    control={<WhiteRadio />}
                    label={translations.chart_view}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={AVAILABLE_VIEWS.table}
                    control={<WhiteRadio />}
                    label={translations.table_view}
                    labelPlacement="end"
                  />
                </RadioGroup>
              </>
            }
          >

            {responseToQuestionView === AVAILABLE_VIEWS.chart ? <StackChart
              questionId={question}
              locale={locale}
              key={`all_data_${new Date()}`}
              title={title}
              subTitle={subTitle}
              name='all_data'
              data={data.chartsResponseToQuestion}
              loading={loading}
              byPercentage={chartsByPercentage.chartsResponseToQuestion}
              translations={translations}
              multianswerQuestion={data.multianswerQuestion}
              demographicSplit={demographicSplit}
            /> : (
                <UNDPTable
                  tableTitle={title}
                  tableSubTitle={subTitle}
                  locale={locale}
                  translations={translations}
                  data={data.questionTable}
                  transposeTable={data.freetextQuestion}
                  loading={loading}
                  multianswerQuestion={data.multianswerQuestion}
                  byPercentage={chartsByPercentage.chartsResponseToQuestion} />
              )
            }
          </Paper>
        </Grid>

        <Grid item md={3} xs={12}>
          {otherFilters()}
        </Grid>

        <Grid item md={9} xs={12}>
          <Paper locale={locale}
            title={translations.total_responses_by_split_and_demographic_category}
            hideRightIcon={data.freetextQuestion}
            rightIcon={
              <div className={classes.viewAs}>
                {translations.view_as}
                <ToggleButtonGroup
                  class={classes.toggleButtonGroup}
                  exclusive
                  onChange={(event, value) => {
                    setChartsByPercentage({
                      ...chartsByPercentage,
                      chartsResponseBySplit: value
                    })
                  }}
                  value={chartsByPercentage.chartsResponseBySplit}
                >
                  <ToggleButton class={classes.toggleButton} value={true} aria-label={translations.show_percentage}>
                    %
                  </ToggleButton>
                  <ToggleButton class={classes.toggleButton} value={false} aria-label={translations.show_count}>
                    1 2 3
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            }
          >

            {!data.freetextQuestion && <StackChart
              questionId={question}
              locale={locale}
              key={`filtered_data_${new Date()}`}
              title={title}
              subTitle={subTitle}
              name='filtered_data'
              data={data.chartsResponseBySplit}
              loading={loading}
              byPercentage={chartsByPercentage.chartsResponseBySplit}
              translations={translations}
              multianswerQuestion={data.multianswerQuestion}
              demographicSplit={demographicSplit}
            />}

            <UNDPTable
              tableTitle={title}
              tableSubTitle={subTitle}
              forceFirstColumn={true}
              locale={locale}
              translations={translations}
              multianswerQuestion={data.multianswerQuestion}
              data={data.filteredTable}
              transposeTable={data.freetextQuestion}
              loading={loading}
              byPercentage={chartsByPercentage.chartsResponseBySplit} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

UNDPFilter.defaultProps = {
  categories: [],
  locale: 'en',
  translations: {}
}

UNDPFilter.propTypes = {
  categories: PropTypes.array,
  locale: PropTypes.string,
  translations: PropTypes.object
}

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('initial_props')
  const data = JSON.parse(node.getAttribute('data'))

  ReactDOM.render(
    <UNDPFilter {...data} />,
    document.getElementById("react-app").appendChild(document.createElement('div')),
  )
})